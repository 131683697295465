.sl-bg body {
	background: #1b1f23;
}

.sl-font {
	font-family: 'Work Sans';
}

.sl-color {
	color: #6db33f;
}
